import {
  SDKMessage,
  SDKMessageType,
} from '../../services/webSdkCommunicatorTypes';
import { LOCAL_STORAGE_KEYS } from '../../services/localStorage';

export const initIframeMessageCommunication = (
  onLoginSuccess: (vytalQrCode: string, vytalUserId: string) => void,
  onModalClose: () => void,
  onNotLoggedIn: () => void,
  onLogout: () => void,
) => {
  const trustedVytalHostnames = [
    'local.vytal.org',
    'web.vytal.org',
    'web-dev.vytal.org',
    'local-web.vytal.org',
  ];

  function onMsg(msg: MessageEvent<any>) {
    const messageOriginUrl = new URL(msg.origin);

    // console.log('onMsg', { messageOriginUrl, msg });

    if (!trustedVytalHostnames.includes(messageOriginUrl.hostname)) {
      // console.warn('message from invalid hostname', msg);
      return;
    }

    const messageDecoded: SDKMessage = JSON.parse(msg.data);
    const { type } = messageDecoded;

    if (
      type === SDKMessageType.LOGIN_SUCCESS_BY_USER ||
      type === SDKMessageType.LOGIN_SUCCESS_AUTO
    ) {
      console.log('login success', messageDecoded);

      if (
        messageDecoded.encryptedJwt &&
        messageDecoded.encryptedRefreshToken &&
        localStorage
      ) {
        // store encrypted jwt and encrypted refresh token in local storage
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_JWT,
          messageDecoded.encryptedJwt,
        );
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_REFRESH_TOKEN,
          messageDecoded.encryptedRefreshToken,
        );
      }

      onLoginSuccess(messageDecoded.vytalQrCode, messageDecoded.userId);
    } else if (type === SDKMessageType.NOT_LOGGED_IN) {
      onNotLoggedIn();
    } else if (type === SDKMessageType.LOGOUT_SUCCESS) {
      if (localStorage) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_JWT);
        localStorage.removeItem(
          LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_REFRESH_TOKEN,
        );
      }
      onLogout();
    } else if (type === SDKMessageType.CLOSE_MODAL) {
      onModalClose();
    } else {
      console.warn('unknown decoded message type', { messageDecoded });
    }
  }

  window.addEventListener('message', onMsg, false);
};
