export const LOCAL_STORAGE_KEYS = {
  VYTAL_APP_LOGIN_TOKEN: 'VYTAL_APP_LOGIN_TOKEN',
  VYTAL_ENCRYPTED_JWT: 'vytal-encrypted-jwt',
  VYTAL_ENCRYPTED_REFRESH_TOKEN: 'vytal-encrypted-refresh-token',
};

interface EncryptedJwtAndRefreshToken {
  encrypted_jwt: string;
  encrypted_refresh_token: string;
}

export const saveEncryptedJwtAndRefreshTokenToLocalStorage = (
  data: EncryptedJwtAndRefreshToken,
) => {
  if (localStorage) {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_JWT,
      data.encrypted_jwt,
    );
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_REFRESH_TOKEN,
      data.encrypted_refresh_token,
    );
  } else {
    console.error('localStorage not available');
  }
};

export const loadEncryptedJwtAndRefreshTokenFromLocalStorage =
  (): EncryptedJwtAndRefreshToken | null => {
    if (localStorage) {
      const encrypted_jwt = localStorage.getItem(
        LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_JWT,
      );
      const encrypted_refresh_token = localStorage.getItem(
        LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_REFRESH_TOKEN,
      );
      if (encrypted_jwt && encrypted_refresh_token) {
        return { encrypted_jwt, encrypted_refresh_token };
      } else {
        return null;
      }
    } else {
      console.error('localStorage not available');
      return null;
    }
  };
