export enum SDKMessageType {
  LOGIN_SUCCESS_BY_USER = 'LOGIN_SUCCESS_BY_USER',
  LOGIN_SUCCESS_AUTO = 'LOGIN_SUCCESS_AUTO',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export interface SDKMessage {
  type: SDKMessageType;
  vytalQrCode: string;
  userId: string;
  encryptedJwt: string;
  encryptedRefreshToken: string;
}
