export enum VytalAccountState {
  LOGGED_IN,
  NOT_LOGGED_IN,
}

const translations = {
  en: {
    singIn: 'Sign in',
    singedIn: 'Logged in',
  },
  fr: {
    singIn: 'Se connecter',
    singedIn: 'Connecté',
  },
  de: {
    singIn: 'Anmelden',
    singedIn: 'Eingeloggt',
  },
  // Add more languages as needed
} as Record<string, Record<string, string>>;

const translate = (text: string) => {
  const lang = navigator.language.split('-')[0]; // Only consider the language part
  if (translations[lang] && translations[lang][text]) {
    return translations[lang][text];
  }
  if (translations.en && translations.en[text]) {
    return translations.en[text];
  }
  return text;
};

export const setVytalAccountIndicator = (
  accountState: VytalAccountState,
  vytalAccountIndicator: HTMLElement,
  vytalButton: HTMLElement,
) => {
  const vytalButtonLogo = vytalButton.querySelector('.vytal-logo');
  vytalButtonLogo.classList.remove('vytal-logo-white');
  vytalButtonLogo.classList.add('vytal-logo-black');

  vytalButton.classList.remove('vytal-button-loading');

  if (accountState === VytalAccountState.LOGGED_IN) {
    vytalAccountIndicator.innerHTML = `
            ${translate('singedIn')} <div class="vytal-checkmark-icon">
        `;
  } else if (accountState === VytalAccountState.NOT_LOGGED_IN) {
    vytalAccountIndicator.innerHTML = `
            <span >${translate('singIn')}</span>
        `;
  } else {
    throw new Error('unsupported accountState');
  }
};
