import { CONFIG } from './config';
import { initIframeMessageCommunication } from './VytalWebCommunicator';
import { setVytalAccountIndicator, VytalAccountState } from './utils/guiHelper';
import { browserDetection } from './utils/BrowserDetection';

import { LOCAL_STORAGE_KEYS } from '../../services/localStorage';

declare global {
  // eslint-disable-next-line no-var
  var VytalWebSdk: {
    init: (element: Node, options: VytalSdkInitOptions) => void;
  };
}

export interface VytalSdkInitOptions {
  onLoginCallback: (result: VytalSdkLoginSuccessResult) => void;
  onLogoutCallback: () => void;
  modalContainer?: Node;
  disableAutoLogin?: boolean;
  clientId?: string;
  requestCookieConsent?: boolean;
  cookieConsentGiven?: boolean;
}

export interface VytalSdkLoginSuccessResult {
  vytalQrCode: string;
  userId: string;
}

const showLoginInIframe =
  !browserDetection.isAnyMobile() && !browserDetection.isSafari();

const vytalWebSdk: any = {};
vytalWebSdk.init = async (element: Node, options: VytalSdkInitOptions) => {
  const {
    disableAutoLogin,
    modalContainer,
    clientId,
    requestCookieConsent,
    cookieConsentGiven,
  } = options;

  // add button
  const vytalButton = document.createElement('a');

  const addClientIdParam = (url: string): string =>
    url + (clientId ? `&client_id=${encodeURIComponent(clientId)}` : '');

  const loginUrl = addClientIdParam(
    CONFIG.loginUrl +
      (disableAutoLogin ? '&disable_auto_login=true' : '') +
      (requestCookieConsent ? '&request_cookie_consent=true' : '') +
      (cookieConsentGiven ? '&cookie_consent_given=true' : ''),
  );

  const homeUrl = addClientIdParam(CONFIG.home);

  let iframeIsLoggedInUrl = addClientIdParam(CONFIG.iframeIsLoggedInUrl);
  if (localStorage) {
    const encryptedJwt = localStorage.getItem(
      LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_JWT,
    );
    const encryptedRefreshToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_REFRESH_TOKEN,
    );
    if (encryptedJwt && encryptedRefreshToken) {
      iframeIsLoggedInUrl += `&encrypted_jwt=${encodeURIComponent(encryptedJwt)}&encrypted_refresh_token=${encodeURIComponent(encryptedRefreshToken)}`;
    }
  }

  if (!showLoginInIframe) {
    vytalButton.setAttribute('href', loginUrl);
    vytalButton.setAttribute('target', '_blank');
    vytalButton.setAttribute('rel', 'opener');
  }
  vytalButton.classList.add('vytal-button');
  vytalButton.classList.add('vytal-button-loading');

  // the iframe is there to check if the user is already logged in
  // we want to have this on a separate page which loads faster and does not need all those
  // social login dependencies
  vytalButton.innerHTML = `
        <div class="vytal-logo vytal-logo-white"></div>
        <iframe class="vytal-is-logged-in-iframe" src="${disableAutoLogin ? '' : iframeIsLoggedInUrl}" frameborder="0" allow="identity-credentials-get" ></iframe>
    `;
  element.appendChild(vytalButton);

  // add account indicator, default is loading state
  const vytalAccountIndicator = document.createElement('div');
  vytalAccountIndicator.setAttribute(
    'data-test-id',
    'account-indicator-button',
  );
  vytalAccountIndicator.classList.add('vytal-button-account-indicator');
  vytalAccountIndicator.innerHTML = `
       <img class="vytal-button-spinner" src="${CONFIG.host}/gif/loading-spinner-black-bg.gif" alt="loading..."/>
    `;
  vytalButton.appendChild(vytalAccountIndicator);

  // add modal
  const modal = document.createElement('div');
  modal.classList.add('vytal-modal');

  if (disableAutoLogin) {
    // when auto login is disabled, we do not have a loading state
    setVytalAccountIndicator(
      VytalAccountState.NOT_LOGGED_IN,
      vytalAccountIndicator,
      vytalButton,
    );
  }

  // modal.classList.add('vytal-modal-open') // DEBUG
  if (showLoginInIframe) {
    modal.innerHTML = `
         <div class="vytal-modal-content">
           <iframe data-test-id="vytal-iframe" class="vytal-iframe" src="${loginUrl}" width="100%" height="100%" frameborder="0" allow="identity-credentials-get" ></iframe>
         </div>
        `;
  }

  if (showLoginInIframe) {
    vytalButton.addEventListener('click', (e) => {
      e.preventDefault();

      document.querySelector('.vytal-modal').classList.add('vytal-modal-open');
    });
  }

  // close modal when user clicks outside of modal
  const closeModal = () => {
    if (!showLoginInIframe) {
      return;
    }
    console.log('close modal', modal);
    modal.classList.remove('vytal-modal-open');
  };
  if (showLoginInIframe) {
    window.addEventListener('click', (e) => {
      if (e.target == modal) {
        closeModal();
      }
    });
  }

  const handleLoginSuccess = (vytalQrCode: string, vytalUserId: string) => {
    closeModal();
    setVytalAccountIndicator(
      VytalAccountState.LOGGED_IN,
      vytalAccountIndicator,
      vytalButton,
    );
    try {
      options.onLoginCallback({ vytalQrCode, userId: vytalUserId });
    } catch (e) {
      console.log('error calling onLoginCallback', e);
    }

    // navigate within iframe to home view
    if (showLoginInIframe) {
      modal.querySelector('.vytal-iframe').setAttribute('src', homeUrl);
    }
  };

  // called on initial check if user has not been logged in yet and if user logs out
  const onNotLoggedIn = () => {
    setVytalAccountIndicator(
      VytalAccountState.NOT_LOGGED_IN,
      vytalAccountIndicator,
      vytalButton,
    );
  };
  const onLogout = () => {
    setVytalAccountIndicator(
      VytalAccountState.NOT_LOGGED_IN,
      vytalAccountIndicator,
      vytalButton,
    );
    options.onLogoutCallback();
  };

  // handle login success callback
  initIframeMessageCommunication(
    handleLoginSuccess,
    closeModal,
    onNotLoggedIn,
    onLogout,
  );

  if (modalContainer) {
    modalContainer.appendChild(modal);
  } else {
    element.appendChild(modal);
  }
};

window.VytalWebSdk = vytalWebSdk;
