const localHost = 'https://local-web.vytal.org'; // this should point to localhost via your local host file

const devHost = 'https://web-dev.vytal.org';
const prodHost = 'https://web.vytal.org';

const loginPath = `/welcome/login?sdk_callback_url=${encodeURIComponent(window.location.href)}`;
const isLoggedInPath = `/web-login-sdk/is-user-logged-in?sdk_callback_url=${encodeURIComponent(window.location.href)}`;
const homePath = `?sdk_callback_url=${encodeURIComponent(window.location.href)}`;
const configs = {
  local: {
    host: localHost,
    loginUrl: localHost + loginPath,
    iframeIsLoggedInUrl: localHost + isLoggedInPath,
    home: localHost + homePath,
  },
  dev: {
    host: devHost,
    loginUrl: devHost + loginPath,
    iframeIsLoggedInUrl: devHost + isLoggedInPath,
    home: devHost + homePath,
  },
  prod: {
    host: prodHost,
    loginUrl: prodHost + loginPath,
    iframeIsLoggedInUrl: prodHost + isLoggedInPath,
    home: prodHost + homePath,
  },
};

const appConfig = configs.local;

// this line gets replaced depending on stage in webpack.config.js
export const CONFIG = configs.prod;
